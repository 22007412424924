<template>
  <v-card class="mb-7" elevation="0" outlined tile>
    <v-card-text class="pa-4">
      <div v-if="elementVisible">
        <vue-apex-charts type="bar" height="400" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'CumplimientoXMes',
  props: {
    datos: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    series: [
      {
        name: 'SI',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'POR VENCER',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      },
      {
        name: 'NO',
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    ],
    chartOptions: {
      grid: {
        show: true,
        borderColor: 'rgba(0, 0, 0, .3)',
        strokeDashArray: 3,
        padding: {
          left: 0,
          right: 0,
          bottom: 0
        }
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '48%',
          endingShape: 'flat'
        }
      },
      colors: ['#27A300', '#02a99e', '#d90429'],
      fill: {
        type: 'solid',
        opacity: 1
      },
      chart: {
        stacked: true,
        toolbar: {
          show: true
        },
        sparkline: {
          enabled: false
        }
      },
      title: {
        text: 'CANTIDAD DE COMPROMISOS POR CUMPLIMIENTO POR MES',
        align: 'center',
        margin: 5,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: '18px',
          fontWeight: 'normal',
          fontFamily: 'Roboto, Helvetica, Arial',
          color: 'rgba(0, 0, 0, 0.8)'
        }
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '14px',
        fontFamily: 'Roboto, Helvetica, Arial',
        markers: {
          width: 14,
          height: 14,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: undefined,
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          colors: '#424242',
          useSeriesColors: false
        },
        itemMargin: {
          horizontal: 15,
          vertical: 5
        },
        onItemHover: {
          highlightDataSeries: true
        },
        onItemClick: {
          toggleDataSeries: true
        }
      },
      dataLabels: {
        enabled: true
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: 'category',
        categories: [
          'ENERO',
          'FEBRERO',
          'MARZO',
          'ABRIL',
          'MAYO',
          'JUNIO',
          'JULIO',
          'AGOSTO',
          'SETIEMBRE',
          'OCTUBRE',
          'NOVIEMBRE',
          'DICIEMBRE'
        ],
        labels: {
          trim: true,
          style: {
            fontSize: '10px',
            fontWeight: 'bolder',
            cssClass: 'text-muted fill-color'
          }
        }
      },
      yaxis: {
        show: true,
        labels: {
          style: {
            cssClass: 'text-muted fill-color'
          }
        }
      },
      stroke: {
        curve: 'straight',
        width: 0
      },
      tooltip: {
        theme: 'dark'
      }
    },
    elementVisible: false
  }),
  watch: {
    async datos(val) {
      let series = [
        {
          name: 'SI',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: 'POR VENCER',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },
        {
          name: 'NO',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        }
      ];

      if (val.acuerdossubareas?.length > 0) {
        val.acuerdossubareas.forEach((element) => {
          series[0].data[element.mes - 1] = series[0].data[element.mes - 1] + parseInt(element.totalsi);
          series[1].data[element.mes - 1] = series[1].data[element.mes - 1] + parseInt(element.totalporvencer);
          series[2].data[element.mes - 1] = series[2].data[element.mes - 1] + parseInt(element.totalno);
        });
        this.elementVisible = true;
      }
      this.series = series;
    }
  },
  created() {},
  components: {
    VueApexCharts
  }
};
</script>
